import { nonAccentVietnamese } from '@iso/lib/helpers/utility';

import companyActions from './actions';

const initialState = {
  companies: [],
  total: 0,
  company: {},
  isFetched: false,
  errorMessage: '',
  status: '',
  users: [],
  contacts: [],
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case companyActions.SET_COMPANIES:
      const { companies, total } = action.payload;

      return {
        ...state,
        companies,
        total,
        isFetched: true,
      };

    case companyActions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case companyActions.SET_COMPANY:
      return {
        ...state,
        company: action.payload,
        isFetched: true,
      };

    case companyActions.CREATE_COMPANY:
      return {
        ...state,
        companies: [
          {
            ...action.payload,
            keyword: `${nonAccentVietnamese(action.payload.name)} ${action.payload.code}`,
          },
          ...state.companies,
        ],
        status: 'CREATED',
        company: {},
      };
    case companyActions.UPDATE_COMPANY:
      return {
        ...state,
        companies: [
          ...state.companies.map(company => {
            return company.id === action.payload.id ? {
              ...company,
              ...action.payload,
              keyword: `${nonAccentVietnamese(action.payload.name)} ${action.payload.code}`,
            } : company;
          })
        ],
        company: action.payload,
        status: 'UPDATED',
      };
    case companyActions.COMPANY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case companyActions.RESET_COMPANY_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };

    case companyActions.SET_COMPANY_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };

    case companyActions.CREATE_CONTACT:
      return {
        ...state,
        contacts: [
          action.payload,
          ...state.contacts,
        ],
        status: 'CONTACT_CREATED',
      };
    case companyActions.UPDATE_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.map(contact => {
            return contact.id === action.payload.id ? {
              ...contact,
              ...action.payload,
            } : contact;
          })
        ],
        status: 'CONTACT_UPDATED',
      };
    case companyActions.DELETE_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter(contact => contact.id !== action.payload.id)
        ],
        status: 'CONTACT_DELETED',
      };
    default:
      return state;
  }
}
