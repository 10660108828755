import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import AuthHelper from '@iso/lib/helpers/authHelper';
import SuperFetch from '@iso/lib/helpers/superFetch';
import jwtDecode from 'jwt-decode';

import actions from './actions';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { email, password } = payload;
    try {
      const { token, ...profile } = yield AuthHelper.login({
        email,
        password,
      });

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile,
      });
    } catch (err) {
      console.error('@err', err);
      yield put({
        type: actions.LOGIN_ERROR,
        message: err.message || 'Email hoặc mật khẩu không hợp lệ',
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    if (payload.token) {
      yield localStorage.setItem('id_token', payload.token);
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    window.location.href = '/';
  });
}
// export function* checkAuthorization() {
//   yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
//     try {
//       const token = getToken().get('idToken');
//       if (token) {
//         const profile = yield AuthHelper.getUserProfile();

//         yield put({
//           type: actions.LOGIN_SUCCESS,
//           token,
//           profile,
//         });
//       }
//     } catch (err) {
//       console.error('@err', err);
//       yield put({
//         type: actions.LOGIN_ERROR,
//         message: err.message || 'Lấy thông tin user thất bại, vui lòng đăng nhập lại',
//       });

//       yield logout();
//     }
//   });
// }

export function* checkAuthorization() {
  const token = getToken().get('idToken');
  if (!token) {
    return;
  }

  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    const profile = jwtDecode(token);

    if (token) {
      yield put({
        profile,
        token,
        type: actions.LOGIN_SUCCESS,
      });
    }
  });
}

function* getProfile() {
  try {
    const profile = yield SuperFetch.get('/auth/profile');

    yield put(actions.setProfile(profile));
  } catch (err) {
    yield put({
      type: actions.PROFILE_ERROR,
      payload: err.message,
    });

    // * Redirect to login page
    window.location.href = '/';
    clearToken();
  }
}

function* updateProfile({ payload }) {
  try {
    const profile = yield SuperFetch.put('/auth/profile', payload);

    yield put(actions.updateProfile(profile));
    if (profile.token) {
      yield localStorage.setItem('id_token', profile.token);
    }
  } catch (err) {
    yield put({
      type: actions.PROFILE_ERROR,
      payload: err.message,
    });
  }
}

function* updatePassword({ payload }) {
  try {
    yield SuperFetch.put('/auth/password', payload);

    yield put(actions.updatePassword());
  } catch (err) {
    yield put({
      type: actions.PROFILE_ERROR,
      payload: err.message,
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),

    takeEvery(actions.GET_PROFILE, getProfile),
    takeEvery(actions.UPDATE_PROFILE_WATCHER, updateProfile),
    takeEvery(actions.UPDATE_PASSWORD_WATCHER, updatePassword),
  ]);
}
